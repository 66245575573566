import React, { ReactElement } from "react";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { getClient } from "@precomposer/data";
import DefaultLayout from "../../components/layout/DefaultLayout";
import { env } from "@precomposer/env";
import { StoryblokComponent, useStoryblokState } from "@storyblok/react";
import { StoryblokSlug } from "../../lib/storyblokSlug";
import { fetchStoryblokStory } from "../../lib/storyblok.util";

export default function Home(
  props: InferGetServerSidePropsType<typeof getServerSideProps>
) {
  let editableStory = useStoryblokState(props.story);

  return (
    <>
      <StoryblokComponent blok={editableStory.content} />
    </>
  );
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export const getServerSideProps: GetServerSideProps<any> = async (context) => {
  const client = getClient();
  const locale = context.locale || env("DEFAULT_LOCALE");

  const storyblokStory = await fetchStoryblokStory(
    `${env("CMS_BASE_PATH")}/${StoryblokSlug.HOME}`.replace(/^\/+|\/+$/g, ""),
    locale,
    client
  );

  if (!storyblokStory) {
    return {
      notFound: true,
    };
  }

  const navigationContent = await fetchStoryblokStory(
    `${env("CMS_BASE_PATH")}`,
    locale,
    client
  );

  return {
    props: {
      story: storyblokStory ? storyblokStory.story : false,
      navigationContent: navigationContent.story.content,
      ...(await serverSideTranslations(locale, [
        "common",
        "account",
        "search",
      ])),
    },
  };
};
